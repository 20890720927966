let hasResume = document.querySelector("#hasResume")
let fileInput = document.querySelector("#requestModal .file-input")

if(hasResume){
    hasResume.onchange = function () {
        console.log()
        if(this.checked){
            fileInput.classList.add("_active")
        }else{
            fileInput.classList.remove("_active")
        }
    }
}

