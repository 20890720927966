
window.addEventListener('DOMContentLoaded', (event) => {
    animate()

    function getSamePageAnchor (link) {
        if (
            link.protocol !== window.location.protocol ||
            link.host !== window.location.host ||
            link.pathname !== window.location.pathname ||
            link.search !== window.location.search
        ) {
            return false;
        }

        return link.hash;
    }

// Scroll to a given hash, preventing the event given if there is one
    function scrollToHash(hash, e) {
        e.preventDefault();

        setTimeout(() => {
            document.querySelector(hash).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }, 100);

    }

// If a link's href is within the current page, scroll to it instead
    document.querySelectorAll('a[href="#"]').forEach(a => {
        a.addEventListener('click', e => {
            e.preventDefault();
            scrollToHash(getSamePageAnchor(a), e)
        });
    });

// Scroll to the element in the URL's hash on load
    if (window.location.hash) {
        scrollToHash(window.location.hash, event);
    }
})

function animate() {

    let mm = gsap.matchMedia();

    mm.add("(min-width: 577px)", () => {
        const tlLines = gsap.timeline({
            scrollTrigger: {
                trigger: '.who__nums',
                start: 'top 40%',
                end: 'bottom 70%',
                scrub: 2
            }
        })
        tlLines.to('.nums', {
            xPercent: -100
        })
    })

    mm.add("(max-width: 576px)", () => {
        const tlLines = gsap.timeline({
            scrollTrigger: {
                trigger: '.who__nums',
                // start: 'top 0%',
                // end: 'bottom 30%',
                scrub: 2
            }
        })
        tlLines.to('.nums', {
            xPercent: -140
        })
    })



    gsap.from('.cool__tag', {
        opacity: 0,
        y: 30,
        stagger: 0.2,
        duration: .05,
        scrollTrigger: {
            trigger: '.cool__tags',
            start: 'top bottom',
        }

    })
}
