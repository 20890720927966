const alertBox = document.querySelector('.js-alert');
const appForm = document.querySelector('.js-app-form');
const telegram = appForm.querySelector('.js-app-field[name="telegram"]');
const constraints = {
  name: {
    presence: {
      allowEmpty: false,
    },
  },
  email: {
    email: true,
  },
  tel: {
    presence: {
      allowEmpty: false,
    },
  },
};

if (appForm) {

  telegram.addEventListener('input', () => {
    telegram.value = telegram.value.replace(/[А-Яа-я]/g, '');
  });

  appForm.addEventListener('submit', (e) => {
    e.preventDefault();

    send();
  });

  const fields = appForm.querySelectorAll('.js-app-field');

  if (fields) {
    fields.forEach((field) => {
      field.addEventListener('focus', () => {
        clearFieldsError(field);
      });
    });
  }
}

async function send() {
  const data = new FormData();

  const name = appForm.querySelector('.js-app-field[name="name"]').value;
  const email = appForm.querySelector('.js-app-field[name="email"]').value;
  const telegram = appForm.querySelector('.js-app-field[name="telegram"]').value;
  const tel = appForm.querySelector('.js-app-field[name="tel"]').value;
  const message = appForm.querySelector('.js-app-field[name="message"]').value;
  const cv = appForm.querySelector('input[name="cv"]');
  const position = document.querySelector('.js-app-position').innerText;

  data.append('name', name);
  data.append('email', email);
  data.append('tel', tel);
  data.append('telegram', telegram);
  data.append('message', message);
  data.append('file', cv.files[0]);
  data.append('position', position);

  const validateResponse = validate({
    name,
    email,
    tel,
    telegram,
  }, constraints, {format: 'detailed'});

  if (!validateResponse) {
    try {
      await fetch('https://api-hr.friendsonly.me/send-message', {
        method: 'POST',
        body: data,
      })
  
      if (cv.files) {
        await fetch('https://api-hr.friendsonly.me/send-file', {
          method: 'POST',
          body: data,
        });
      }

      toggleAlertBox(alertBox, 'success');
    } catch (error) {
      toggleAlertBox(alertBox, 'error');
    } finally {
      appForm.reset();
    }

    return;
  }

  sendErrorsToUI(validateResponse);
}

function sendErrorsToUI(validateResponse) {
  if (!validateResponse) return;

  for (let i = 0; i < validateResponse.length; i++) {
    const fieldsName = validateResponse[i].attribute;
    const field = document.querySelector(`.js-app-field[name="${fieldsName}"]`);

    field.classList.add('error');
  }
}

function clearFieldsError(field) {
  field.classList.remove('error');
}

function toggleAlertBox(box, status) {
  box.classList.remove('success', 'error', 'show');
  box.classList.add(status);

  box.classList.add('show');

  setTimeout(() => {
    box.classList.remove('show');
  }, 4000);
}