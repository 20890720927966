let modalTogglers = document.querySelectorAll("[data-modal]")
modalTogglers.forEach(el => {
    el.onclick = function () {
        let modalId = el.getAttribute("data-modal")
        let title = el.getAttribute("data-title")
        let modal = document.querySelector(`#${modalId}`)
        document.querySelector(`#${modalId} .modal__desc`).innerHTML = title
        modal.classList.add("modal--active")
        body.classList.add("fixed-body")


        document.querySelector(`#${modalId} .modal__close`).onclick = function (e) {
            modal.classList.remove("modal--active")
            body.classList.remove("fixed-body")
        }

        document.querySelector(`#${modalId} .modal__bg`).onclick = function (e) {
            modal.classList.remove("modal--active")
            body.classList.remove("fixed-body")
        }
    }
})
