function teamSlider() {
    var swiper = new Swiper('.team .swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 0,
    })
}

function workHereSlider() {
    var swiper = new Swiper('.work-here .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 10,
        scrollbar: {
            el: '.work-here .swiper-scrollbar',
            draggable: true,
        },
    })
}

teamSlider()
workHereSlider()
